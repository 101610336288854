import React from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import './CertifiedCard.scss'

export interface Certified {
  src: IGatsbyImageData
  text1: string
  text2: string
  text1Hindi?: string
  text2Hindi?: string
}

interface Props {
  certified: Certified
  textColor?: string
  lang?: string
}

const CertifiedCard: React.FC<Props> = ({
  certified,
  textColor = '#000',
  lang,
}) => {
  return (
    <div className="certified-card">
      <div className="certification-image">
        <GatsbyImage
          image={certified.src}
          loading="eager"
          alt={certified.text1}
        />
      </div>
      <div className="text">
        <p className="certified-text" style={{ color: textColor }}>
          {lang === 'hindi'
            ? certified.text1Hindi || certified.text1
            : certified.text1}{' '}
          <br />
          {lang === 'hindi'
            ? certified.text2Hindi || certified.text2
            : certified.text2}
        </p>
      </div>
    </div>
  )
}

export default CertifiedCard
