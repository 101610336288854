import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './RatingAndDownloads.scss'

interface Props {
  color: string
}

const RatingAndDownloads: React.FC<Props> = ({ color }) => {
  return (
    <div className="rating" style={{ color }}>
      <div>
        <StaticImage
          loading="eager"
          src="../../../images/star-48.png"
          alt="star"
          className="rating-star"
          width={300}
        />
        <span>4.7 Rating</span>
      </div>
      <div className="rating-separator">•</div>
      <div>50,00,000+ Downloads</div>
    </div>
  )
}

export default RatingAndDownloads
