import React, { useState } from 'react'
import { Carousel, Container } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import { Swipeable } from 'react-swipeable'
import InstructionCard from './InstructionCard'
import Title from '../Title/Title'
import './InstructionCarousel.scss'

const InstructionCarousel: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      step1: file(
        relativePath: { eq: "install-instruction/Install page image 1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, layout: CONSTRAINED)
        }
      }
      step2: file(
        relativePath: { eq: "install-instruction/Install page image 2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, layout: CONSTRAINED)
        }
      }
      step3: file(
        relativePath: { eq: "install-instruction/Install page image 3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, layout: CONSTRAINED)
        }
      }
      step4: file(
        relativePath: { eq: "install-instruction/Install page image 4.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, layout: CONSTRAINED)
        }
      }
    }
  `)

  const [visibleStep, setVisibleStep] = useState(0)
  const instructions = [
    {
      title: 'Step 1 > Download File',
      desc: 'Download Mega app by clicking on the download button below. Wait for the download to finish.',
      image: data.step1.childImageSharp.gatsbyImageData,
      dotColor: '#37D6C2',
    },
    {
      title: 'Step 2 > Open file',
      desc: 'Click on ‘Open’ to launch the App. You will see a prompt appear.',
      image: data.step2.childImageSharp.gatsbyImageData,
      dotColor: '#FF3D3C',
    },
    {
      title: 'Step 3 > Click on settings',
      desc: 'If you are prompted with the below message, Click on ‘Settings’.',
      image: data.step3.childImageSharp.gatsbyImageData,
      dotColor: '#FFD608',
    },
    {
      title: 'Step 4 > Allow Installation',
      desc: 'In ‘Settings’ > ‘Security’ > Turn on toggle for ‘Unknown sources’. Your app will be installed ! Open or relaunch your app.',
      image: data.step4.childImageSharp.gatsbyImageData,
      dotColor: '#DDDDDD',
    },
  ]

  return (
    <div className="instructions">
      <Container>
        <Title color="#fff">
          How to install <br className="d-block d-md-none" />
          the app in <span className="underline">your phone</span>
        </Title>
        <Swipeable
          preventDefaultTouchmoveEvent
          className="swipe-div d-block d-md-none"
        >
          <Carousel
            controls={false}
            indicators={false}
            interval={1000 * 1000}
            onSlide={i => {
              setVisibleStep(i)
            }}
          >
            {instructions.map(i => {
              return (
                <Carousel.Item key={i.title}>
                  <InstructionCard
                    title={i.title}
                    desc={i.desc}
                    image={i.image}
                  />
                </Carousel.Item>
              )
            })}
          </Carousel>
          <div className="dots">
            {instructions.map((t, i) => {
              return (
                <div
                  key={`${t.dotColor}instruction`}
                  className={`dot ${visibleStep === i ? 'active' : ''}`}
                  style={{ background: t.dotColor }}
                />
              )
            })}
          </div>
        </Swipeable>

        <div className="d-none d-md-block">
          <div className="desktop-grid">
            {instructions.map(i => {
              return (
                <InstructionCard
                  key={i.title}
                  title={i.title}
                  desc={i.desc}
                  image={i.image}
                />
              )
            })}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default InstructionCarousel
