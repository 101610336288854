import React, { useState } from 'react'
import LinkButton from '../../../LinkButton/LinkButton'
import PopupDownload from '../../../PopupDownload/PopupDownload'
import MegaFeatures from '../MegaFeatures'
import RatingAndDownloads from '../RatingAndDownloads'
import SendSms from './SendSms'
import InstallHandler from '../InstallHandler'
import './Sms.scss'
import {
  rummyOneLinksHomePage,
  pokerOneLinksHomePage,
} from '../../../one-links'

interface Props {
  title?: string | React.ReactNode
  description?: React.ReactNode
  hideFeatures?: boolean
  hideTitle?: boolean
  hideButtonRating?: boolean
  showForm?: boolean
  showApps?: boolean
}

const Sms: React.FC<Props> = ({
  title,
  description,
  hideFeatures,
  hideButtonRating,
  hideTitle,
  showForm,
  showApps,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const popupCode = isPopupOpen ? (
    <PopupDownload
      close={() => {
        setIsPopupOpen(false)
      }}
    />
  ) : null

  const defaultTitle = !hideTitle ? (
    <h1 className="title white-text">
      <span>India&apos;s Best Rummy &amp; Poker Brand.</span>
      Real players. Real money. Real thrill.
    </h1>
  ) : null

  return (
    <>
      <div className="sms-form">
        {title ? <h1 className="title white-text">{title}</h1> : defaultTitle}

        {description ? <>{description}</> : null}

        {showApps && (
          <InstallHandler
            rummyLink={rummyOneLinksHomePage}
            pokerLink={pokerOneLinksHomePage}
          />
        )}

        {showForm && (
          <>
            <div className="d-none d-sm-block desktop-btn-wrap">
              <LinkButton
                className="popBtn inline-btn"
                onClick={() => {
                  setIsPopupOpen(true)
                }}
              >
                Play Now
              </LinkButton>
            </div>
            <div className="d-block d-sm-none">
              <SendSms downloadBtnText="Play Now" />
            </div>
          </>
        )}

        {!hideButtonRating && (
          <div className="d-block d-md-none">
            <div className="rate-download-wrap">
              <RatingAndDownloads color="white" />
            </div>
          </div>
        )}

        {!hideFeatures && <MegaFeatures />}
      </div>
      {popupCode}
    </>
  )
}

export default Sms
