import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './MegaFeatures.scss'

const MegaFeatures: React.FC = () => {
  return (
    <div className="MegaFeatures">
      <div className="features-wrap">
        <div className="feature-item">
          <StaticImage
            loading="eager"
            src="../../../images/referral/icon1.png"
            alt="Mega feature"
            imgStyle={{ objectFit: 'contain' }}
            width={36}
          />
          <span>
            Instant <br />
            Withdrawal
          </span>
        </div>
        <div className="feature-item">
          <StaticImage
            loading="eager"
            src="../../../images/referral/icon2.png"
            alt="Mega feature"
            imgStyle={{ objectFit: 'contain' }}
            width={36}
          />
          <span>
            Legally <br />
            Approved
          </span>
        </div>
        <div className="feature-item">
          <StaticImage
            loading="eager"
            src="../../../images/referral/icon3.png"
            alt="Mega feature"
            imgStyle={{ objectFit: 'contain' }}
            width={36}
          />
          <span>
            Fair Gameplay <br />
            Certified
          </span>
        </div>
        <div className="feature-item">
          <StaticImage
            loading="eager"
            src="../../../images/referral/icon4.png"
            alt="Mega feature"
            imgStyle={{ objectFit: 'contain' }}
            width={36}
          />
          <span>
            5M+ <br />
            Downloads
          </span>
        </div>
      </div>
    </div>
  )
}

export default MegaFeatures
