import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import DownloadContent from '../components/Download/Download'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { DOWNLOAD as DOWNLOAD_LINK } from '../components/internal-links'
import { INSTALL_PAGE } from '../components/download-links'
import { Faq } from '../components/FAQs/faqs-list-interface'
import {
  generateBreadcrumbsSchema,
  generateDownloadInstructionVideoSchema,
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Install Real Money Gaming App',
    url: DOWNLOAD_LINK,
  },
]

const Download: React.FC = () => {
  const [downloadFaqsSt, setDownloadFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../components/FAQs/faqs-list').then(({ downloadFaqs }) =>
      setDownloadFaqsSt(downloadFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={INSTALL_PAGE}
      pokerOneLink={pokerOneLinksStaticPage}
      rummyOneLink={rummyOneLinksStaticPage}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Mega: Earn Real Money Gaming App | 5 million+ Installs | Download Now!"
        description="Blend the joy of gaming with the thrill of real money. Download the Mega android app, play exciting cards, casual & trivia games, and withdraw your winnings instantly."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(downloadFaqsSt)}
        videoSchema={[generateDownloadInstructionVideoSchema()]}
      />
      <DownloadContent />
    </Layout>
  )
}

export default Download
