import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// import { Swipeable } from 'react-swipeable'
// eslint-disable-next-line
// @ts-ignore
import ReadMoreAndLess from 'react-read-more-less'

import './SafeAndCertified.scss'
import Container from 'react-bootstrap/esm/Container'
import Title from '../../Title/Title'
import CertifiedCard, { Certified } from './CertifiedCard'
import useShowAll from '../../../hooks/useShowAll'
import { GAMES, PRIVACY_POLICY, RUMMY, POKER } from '../../internal-links'
// import scrollToSection from '../SwipeManager/scroll-to-section'

interface Props {
  titleColor?: string
  bg?: string
  showDesc?: boolean
  showHeroImage?: boolean
  showLinks?: boolean
  textColor?: string
  lang?: string
  hideGame?: boolean
  title?: string
  titleHindi?: string
  containerStyles?: React.CSSProperties
}

const SafeAndCertified: React.FC<Props> = ({
  titleColor = ' #2270da',
  bg = '#fff',
  showDesc = true,
  showHeroImage = false,
  showLinks = true,
  textColor = '#000',
  lang,
  hideGame = false,
  title = '100% Safe and Certified',
  titleHindi = "'100% सुरक्षित और प्रमाणित'",
  containerStyles,
}) => {
  const data = useStaticQuery(graphql`
    {
      safe: file(relativePath: { eq: "safe.png" }) {
        childImageSharp {
          gatsbyImageData(width: 315, layout: CONSTRAINED)
        }
      }
      icon1: file(relativePath: { eq: "safe/icon1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      icon2: file(relativePath: { eq: "safe/icon2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      icon3: file(relativePath: { eq: "safe/icon3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      icon4: file(relativePath: { eq: "safe/icon4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      icon5: file(relativePath: { eq: "safe/icon5.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
      icon6: file(relativePath: { eq: "safe/icon6.png" }) {
        childImageSharp {
          gatsbyImageData(width: 60, layout: CONSTRAINED)
        }
      }
    }
  `)

  const certifiedCards: Certified[] = [
    {
      src: data.icon1.childImageSharp.gatsbyImageData,
      text1: 'All India',
      text2: 'Gaming Federation',
      text1Hindi: 'ऑल इंडिया',
      text2Hindi: 'गेमिंग फेडरेशन',
    },
    {
      src: data.icon2.childImageSharp.gatsbyImageData,
      text1: 'RNG',
      text2: 'Certification',
      text1Hindi: 'आरएनजी',
      text2Hindi: 'प्रमाणपत्र',
    },
    {
      src: data.icon3.childImageSharp.gatsbyImageData,
      text1: 'Poker Game Fairness',
      text2: 'Certificate',
      text1Hindi: 'पोकर गेम फेयरनेस',
      text2Hindi: 'सर्टिफिकेट',
    },
    {
      src: data.icon4.childImageSharp.gatsbyImageData,
      text1: 'Instant',
      text2: 'Withdrawal',
      text1Hindi: 'तत्काल',
      text2Hindi: 'निकासी',
    },
  ]

  const showAll = useShowAll()
  
  return (
    // <Swipeable
    //   onSwipedDown={() => {
    //     scrollToSection('features')
    //   }}
    //   preventDefaultTouchmoveEvent
    // >
    // </Swipeable>
    <div id="safe-and-certified" style={{ background: bg, ...containerStyles }}>
      <Container>
        {showHeroImage ? (
          <div className="image">
            <GatsbyImage
              image={data.safe.childImageSharp.gatsbyImageData}
              loading="lazy"
              imgStyle={{ objectFit: 'contain' }}
              alt="safe and certified"
              className="image-item"
            />
          </div>
        ) : null}

        <Title color={titleColor} className="title">
          {lang === 'hindi' ? titleHindi : title}
        </Title>

        <div className="cards">
          {certifiedCards.map(c => {
            return (
              <CertifiedCard
                key={c.text1}
                certified={c}
                textColor={textColor}
                lang={lang}
              />
            )
          })}
        </div>

        {showLinks ? (
          <div className="links">
            <Link to="/certifications/">
              {lang === 'hindi' ? 'प्रमाण पत्र देखें' : 'View Certificates'}
            </Link>
            <span>&nbsp; &nbsp; | &nbsp; &nbsp;</span>
            {hideGame ? (
              <>
                <Link to={RUMMY}>
                  {lang === 'hindi' ? 'रम्मी खेलें' : 'Play Rummy'}
                </Link>
                <span>&nbsp; &nbsp; | &nbsp; &nbsp;</span>
                <Link to={POKER}>
                  {lang === 'hindi' ? 'पोकर खेलें' : 'Play Poker'}
                </Link>
              </>
            ) : (
              <Link to={GAMES}>
                {lang === 'hindi'
                  ? 'सभी खेलों का अन्वेषण करें'
                  : 'Explore All Games'}
              </Link>
            )}

            <span>&nbsp; &nbsp; | &nbsp; &nbsp;</span>
            <Link to={PRIVACY_POLICY}>
              {lang === 'hindi' ? 'गोपनीयता नीति' : 'Privacy Policy'}
            </Link>
          </div>
        ) : null}
      </Container>
    </div>
  )
}

export default SafeAndCertified
