import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import DownloadsAndRating from '../Index/MainScreen/Desktop/DownloadsAndRating'
import SafeAndCertified from '../Index/SafeAndCertified/SafeAndCertified'
import HomeTestimonials from '../Index/Testimonials/HomeTestimonials'
import './Download.scss'
import useTestimonials from '../Testimonials/useTestimonials'
import HomeFAQs from '../Index/HomeFAQs/HomeFAQs'
import DownloadInfo from '../Games/CategoryAndGamesInfo/DownloadInfo'
import { Faq } from '../FAQs/faqs-list-interface'
import Sms from '../Index/MainScreen/Desktop/Sms'
import InstructionCarousel from './InstructionCarousel'
import LinkButton from '../LinkButton/LinkButton'
import PopupDownload from '../PopupDownload/PopupDownload'

const Download: React.FC = () => {
  const [downloadFaqsSt, setDownloadFaqsSt] = useState<Faq[]>([])

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const popupCode = isPopupOpen ? (
    <PopupDownload
      close={() => {
        setIsPopupOpen(false)
      }}
    />
  ) : null

  useEffect(() => {
    import('../FAQs/faqs-list').then(({ downloadFaqs }) =>
      setDownloadFaqsSt(downloadFaqs)
    )
  }, [])

  const data = useStaticQuery(graphql`
    {
      image1: file(
        relativePath: { eq: "install-instruction/Group 2 Copy 9.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      image2: file(
        relativePath: { eq: "install-instruction/Group 2 Copy 8.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      image3: file(
        relativePath: { eq: "install-instruction/Group 2 Copy 4-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      star: file(relativePath: { eq: "star-48.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, layout: CONSTRAINED)
        }
      }
      logo: file(relativePath: { eq: "getMega_WordLogo_Black.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const { installAppTestimonials } = useTestimonials()

  const smsTitle = (
    <>
      Install now to play amazing games and win{' '}
      <span className="underline">real money</span>.
    </>
  )

  return (
    <>
      <div id="Download">
        <div className="d-sm-none rate-download-wrap">
          <Container>
            <DownloadsAndRating />
            <div className="download-btn-wrap">
              <LinkButton
                className="popBtn inline-btn"
                onClick={() => {
                  setIsPopupOpen(true)
                }}
              >
                Play Now
              </LinkButton>
            </div>
          </Container>
        </div>

        <div className="sms sms-custom d-none d-sm-block">
          <Container>
            <Row>
              <Col lg={7}>
                <Sms title={smsTitle} hideButtonRating showForm />
              </Col>
              <Col>
                <div className="video">
                  <div className="embed-container">
                    <iframe
                      title="video"
                      src="https://player.vimeo.com/video/475764833?title=0&byline=0&portrait=0&autoplay=0&background=0"
                      width="640"
                      height="1138"
                      frameBorder="0"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <SafeAndCertified
          bg="#191919"
          titleColor="#fff"
          showDesc={false}
          showLinks={false}
          textColor="#fff"
        />

        <InstructionCarousel />

        <div className="download-info">
          <Container className="desktop-info-div">
            <div className="desktop-app-info">
              <div className="megashots">
                <div className="m-logo">
                  <GatsbyImage
                    image={data.logo.childImageSharp.gatsbyImageData}
                    loading="eager"
                    alt="logo"
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </div>
                <div className="info">
                  <div className="c-name">Mega</div>
                  <div className="org-name">
                    Megashots Internet Private Limited
                  </div>
                  <div className="desc">
                    <div className="pill">Real Money</div>
                    <div className="pill">Genuine Profiles</div>
                    <div className="pill">Top Games</div>
                    <div className="rating">
                      <GatsbyImage
                        image={data.star.childImageSharp.gatsbyImageData}
                        loading="eager"
                        alt="star"
                        className="rating-star"
                      />
                      <span>4.7 Rating</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-title app-info">App Info</div>
              <div className="card">
                <div className="info-div">Downloads</div>
                <div className="info-div value">50,000,000+</div>
                <div className="info-div">Download Size</div>
                <div className="info-div value">21 MB</div>
                <div className="info-div">OS</div>
                <div className="info-div value">
                  Android
                  <br />
                  Coming Soon on iOS
                </div>
                <div className="info-div">Owned by</div>
                <div className="info-div value">
                  Megashots Internet Private Limited
                </div>
              </div>
            </div>
          </Container>
          <Container>
            <div className="images">
              <GatsbyImage
                image={data.image1.childImageSharp.gatsbyImageData}
                loading="eager"
                alt=""
              />
              <GatsbyImage
                image={data.image2.childImageSharp.gatsbyImageData}
                loading="eager"
                alt=""
              />
              <GatsbyImage
                image={data.image3.childImageSharp.gatsbyImageData}
                loading="eager"
                alt=""
              />
            </div>
          </Container>
          <Container className="d-md-none">
            <div className="page-title app-info">App Info</div>
            <div className="mobile-app-info">
              <div className="info-div">Downloads</div>
              <div className="info-div">50,00,000+</div>
              <div className="info-div">Download Size</div>
              <div className="info-div">21 MB</div>
              <div className="info-div">OS</div>
              <div className="info-div">
                Android
                <br />
                Coming Soon on iOS
              </div>
              <div className="info-div">Owned by</div>
              <div className="info-div">Megashots Internet Private Limited</div>
              <div className="info-div">Released on</div>
              <div className="info-div">Nov 25, 2018</div>
            </div>
          </Container>
          <Container>
            <DownloadInfo />
          </Container>
        </div>
        <div style={{ background: '#000', paddingBottom: '40px' }}>
          <HomeTestimonials
            color="#000"
            testimonials={installAppTestimonials}
          />
        </div>
        <div
          style={{
            background: '#000',
            paddingTop: '40px',
            paddingBottom: '80px',
          }}
        >
          <HomeFAQs faqs={downloadFaqsSt} />
        </div>
      </div>
      {popupCode}
    </>
  )
}

export default Download
