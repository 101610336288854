import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './DownloadsAndRating.scss'
import RatingAndDownloads from '../RatingAndDownloads'

const DownloadsAndRating: React.FC = () => {
  return (
    <div className="download-and-rating">
      <div>
        <StaticImage
          loading="eager"
          src="../../../../images/getmega-logo-square-white.png"
          alt="Mega logo"
          imgStyle={{ objectFit: 'contain' }}
          width={120}
        />
      </div>
      <div className="info">
        <div className="c">Mega</div>
        <RatingAndDownloads color="#fff" />
      </div>
    </div>
  )
}

export default DownloadsAndRating
