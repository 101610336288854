import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import './InstructionCard.scss'

interface Props {
  title: string
  desc?: string
  image: FluidObject
}

const InstructionCard: React.FC<Props> = ({ title, desc, image }) => {
  return (
    <div className="InstructionCard">
      <div className="text">
        <div className="t">{title}</div>
        {desc && <div className="d">{desc}</div>}
      </div>
      <div className="img">
        <GatsbyImage image={image} loading="eager" alt={title} />
      </div>
    </div>
  )
}

export default InstructionCard
