import React from 'react'
import './CategoryInfo.scss'

const DownloadInfo: React.FC = () => {
  return (
    <div className="category-info">
      <h2 className="page-title">About Our App</h2>
      <p>
        What if games could be something more? More than a way to kill time.
        More than just entertainment.
      </p>
      <p>
        What if you could play your favourite games - Poker &amp; Rummy, all in
        the same place? What if you could install a single app, and play these
        games against real players in real time, 24x7! No bots, no waiting, no
        hacks, no tricks! Sounds fun?
      </p>
      <p>
        What if you could play games and earn real money rewards from the same
        app. Games on a mobile, with the possibility of earning real cash on an
        app. Too good to be true? Not anymore.
      </p>
      <p>
        Play your favourite Cards games on Mega - India&apos;s hottest real
        money gaming app. Experience the joy of gaming with the thrill of real
        money.
      </p>
      <p>
        Pick a game you like, join any table, and start playing with a small
        buy-in amount. Beat your opponents and discover the thrill of earning
        money with a gaming app!
      </p>
      <p>
        The games on Mega are truly games of skill, and are played only against
        100% verified profiles, in real time. Thus, the challenge to win and to
        earn money with our gaming app, is as tough as the toughest player on
        the table. Only your gaming skills stand between your win and your loss,
        between your wallet and the real cash prize. You’ve learnt these skills
        by playing games for so long; it’s time to start winning money using
        those.
      </p>
      <p>
        If you can aim the striker with precision, answer the questions the
        fastest, or find the right cards to match together, Mega is the perfect
        real money gaming app to put your hidden talents to work.
      </p>

      <h2 className="page-title">How to use the app.</h2>
      <h3>Set up your account</h3>
      <ul>
        <li>
          <p>Download the lightweight app within a minute.</p>
        </li>
        <li>
          <p>Install it by giving the necessary permissions.</p>
        </li>
        <li>
          <p>
            Sign up with your Facebook account (we don’t post anything on your
            FB profile).
          </p>
        </li>
        <li>
          <p>Register your mobile number.</p>
        </li>
        <li>
          <p>
            Use your free sign-up bonus or add money to your Deposit Wallet to
            start playing.
          </p>
        </li>
        <li>
          <p>
            Money can be added using any payment method of your choice (PayTM,
            UPI, NetBanking, &amp; more). Look out for exciting cashback offers.
          </p>
        </li>
      </ul>

      <h3>Play games. Win exciting prizes.</h3>
      <ul>
        <li>
          <p>Choose your favourite game.</p>
        </li>
        <li>
          <p>Play at any table. Enter live contests to earn more rewards.</p>
        </li>
        <li>
          <p>Beat real players and win exciting prizes.</p>
        </li>
      </ul>

      <h3>Withdraw your Winnings</h3>
      <ul>
        <li>
          <p>Your winnings get credited to your winnings wallet.</p>
        </li>
        <li>
          <p>Complete your one-time KYC (mandatory for all users).</p>
        </li>
        <li>
          <p>
            Transfer your winnings seamlessly, through UPI, to your PayTM wallet
            or directly to your bank account.
          </p>
        </li>
      </ul>

      <h2 className="page-title">How to play on the app.</h2>
      <h3>Choose your Category.</h3>
      <p>Find your favourite real money earning card games on the app.</p>

      <h3>Select a Game</h3>
      <p>
        Join the game and choose a contest that suits you. The entry fee to the
        games start as low as ₹1, so there’s something for everyone.
      </p>

      <h3>Enter a contest.</h3>
      <p>Pick a table. Enjoy the game.</p>
      <p>
        The game rules and the cash format rules are displayed before the
        beginning of each game.
      </p>

      <h3>Showcase your skills.</h3>
      <p>Beat your opponents to the clock, and win real cash prizes.</p>
    </div>
  )
}

export default DownloadInfo
